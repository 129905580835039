@import '../../../styles/globalVariables';
@import '../../../styles/mixins';

.followIcon {
  @include followIcon;
}
.viewdetailBtn {
  padding: 6px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  height: 36px;
  width: 152px;
  gap: 4px;
  background: #ffffff;
  border: 1px solid #6f10a2;
  border-radius: 8px;
  color: #6f10a2;
  cursor: pointer;
  @include for-size(phone-only) {
    width: calc(100% / 2);
  }

  i {
    display: block;
    width: 16px;
    height: 16px;
  }
}
.bidIcon {
  i {
    display: block;
    width: 16px;
    height: 16px;
  }
}

@include for-size(tablet-up) {
  .isNotMobile {
    display: none;
  }
}
@include for-size(phone-only) {
  .isNotDesktop {
      display: none;
  }
}