@import '../../../../styles/globalVariables';
@import '../../../../styles/mixins';


$responsive-breakpoint: 1023px;
$mobile-breakpoint: 767px;

.cursor_pointer {
  cursor: pointer;
  width: calc((100% - 36px) / 3);

  @include for-size(laptop-down) {
    width: calc((100% - 40px) / 3);
  }

  @include for-size(desktop-down) {
    width: calc((100% - 20px) / 2);
  }

  @include for-size(landscape-phone) {
    width: 100%;
  }
}

.card {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: #ffffff;

  @media screen and (min-width: 480px) and (max-width: 960) {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $card-border-dark;
    grid-gap: 0;
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: transparent;
  }

  @include for-size(landscape-phone) {
    flex-wrap: wrap;
  }

  .liveBidCount {
    display: flex;
    align-items: center;

    &:before {
      content: '';
      width: 4px;
      height: 4px;
      background-color: #fff;
      border-radius: 50%;
      margin: 0 4px;
    }
  }
}

.liveBidCountArabic {
  html[dir='rtl'] & {
    display: flex;
    padding-right: 4px;
    align-items: center;
    grid-gap: 4px
  }

  .bidCount {
    font-size: 12px;
    order: 1;
  }
}

.cancelStampWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
  img {
    width: 204px !important;
    height: 164px !important;
    position: relative !important;
    margin-top: -20px;
    @include for-size(phone-only) {
      margin-top: -50px;
    }
  }
}

.cardDetails {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.figure {
  position: relative;
  width: 100%;
  min-height: 224px;
  cursor: pointer;
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
  aspect-ratio: 16/9;
  
  @media screen and (min-width: 480px) and (max-width: 960) {
    border-radius: 8px;
    overflow: hidden;
  }

  @include for-size(landscape-phone) {
    min-height: inherit;
    max-height: 280px;
  }
}

.imagefit {
  object-fit: cover;
  width:100%;
  height:100%
}
.nextImage {
  object-fit: cover;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);

  html[dir='rtl'] & {
    left: auto !important;
    right: 50% !important;
    transform: translate(50%, -50%);
  }
}

.followIcon {
  @include followIcon;
}

.auctionGroupName{
  color: #4C4C4C;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 16px;
}
.imagelink {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.auctionTitleTag {
  display: flex;
  gap: 6px;

  span {
    background: #FFFFFF;
    border: 1px solid #D2D2D7;
    border-radius: 4px;
    color: $color-gray;
    font-size: 14px;
    font-weight: 400;
    padding: 0.5px 6px;
  }
}

.auctionTitle {
  font-weight: 500;
  font-size: 20px;
  background: #FFFFFF;
  border-radius: 4px;
  width: 100%;
  color: #000;
  padding: 0px 0px 8px 0px;

  span {
    color: #737373;
    font-size: 10px;
    font-weight: 400;
  }
}

.overlayRight {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18px, 18px));
  grid-template-rows: 1fr;
  grid-gap: 16px;
  -moz-box-pack: end;
  justify-content: end;
  justify-self: end;
  align-items: space-between;
  cursor: pointer;
}

.overlayBottom {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: right;
  vertical-align: bottom;

  &__tooltip {
    position: absolute;
    display: none;
    top: 50%;
    right: 100%;
    background-color: #9a92a0;
    color: #fff;
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    padding: 6px 20px;
    border-radius: 4px;
    transform: translate(10%, -50%);

    .overlayBottom:hover & {
      display: block;

      @include for-size(phone-only) {
        :hover {
          display: none;
        }
      }
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      left: 100%;
      width: 0;
      height: 0;
      border: 8px solid;
      border-color: transparent transparent transparent #9a92a0;
      transform: translateY(-50%);
    }
  }
}

.content {
  padding: 0px 16px 8px;
  background: #fff;
  position: relative;
  border-radius: 0 0 8px 8px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 480px) and (max-width: 960) {
    padding-top: 0;
  }

  @include for-size(phone-only) {
    padding: 0px 12px 10px;
  }
}

.title_wrapper {
  padding: 8px 12px;
  overflow: hidden;
  background-color: #f9f5fe;
}

.title {
  display: block;
  font-size: 15px;
  line-height: 32px;
  font-weight: 800;
  color: #510c76;
  text-decoration: none;
  overflow: scroll;
  white-space: nowrap;

  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  min-height: 32px;
}

.title::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}


.address {
  display: block;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-top: 2px;
  color: #737373;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;

  @include for-size(phone-only) {
    max-width: 285px;
  }
}

.attribute {
  padding: 19px 0 0px 0;
  display: flex;
  grid-gap: 28px;
  align-items: start;
  border-bottom: none;
  .infathAuctionIcon{
    i{
      font-size: 24px;
    }
  }
  @media screen and (max-width:360px) {
    grid-gap: 22px;
  }

  &__type {
    display: grid;
    grid-template-rows: repeat(2, auto);
    align-items: end;
  }

  &__image {
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: start;
    align-items: baseline;
    grid-gap: 8px;
    font-size: 16px;
    color: $purple;

    img {
      width: 15px !important;
      height: auto !important;
    }

    i {
      display: flex;
      color: #737373 !important;
    }
  }

  &__count {
    position: relative;
    font-weight: 400;
    color: #191919;
    font-size: 16px;
    line-height: 16px;

    strong {
      font-weight: 500;
      font-size: 18px;
    }

    &:after {
      content: attr(data-unit);
      padding-left: 2px;
      font-weight: 400;
      font-size: 10px;
      text-transform: lowercase;
      color: #737373;

      [dir='rtl'] & {
        margin-right: 5px;
      }
    }
    
    html[dir='rtl'] & {
      font-weight: 500;
      font-size: 18px;
      line-height: 16px;
    }
  }

  &__name {
    font-size: 10px;
    line-height: 16px;
    color: $color-gray;
    text-transform: capitalize;
    font-weight: bold;
    white-space: nowrap;
  }
}

.verticalattribute {
  display: block;
  padding-top: 10px;

  .type {
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  .image {
    gap: 4px;
    height: 16px;

    i {
      display: inline-block;
    }
  }

  @include for-size(phone-only) {
    padding-top: 28px;
  }
}


.footer_wrapper {
  display: flex;
  margin-top: 16px;
}

.footer {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  padding-top: 16px;

  @media screen and (min-width: 480px) and (max-width: 960) {
    padding-bottom: 0;
  }
}

.auction_ends_time {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: end;
  padding-top: 2px;

  @media screen and (min-width: 480px) and (max-width: 960) {
    padding-bottom: 0;
  }
}

.below_strip {
  text-align: center;
  background: #6f10a2;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  justify-content: center;
  padding: 14px 10px 10px;
  align-items: center;
  min-height: 45px;
  position: relative;
  cursor: pointer;

  &.winner_strip {
    background: linear-gradient(180deg, #377DEE 0%, #1E22B6 100%);
    padding: 11px 10px 4px;
    min-height: 46px;

    .win_text {
      font-weight: 400;
      font-size: 14px;
      line-height: 13px;
      color: #FFFFFF;
      margin-left: 8px;

      [dir='rtl'] & {
        margin-left: 0px;
        margin-right: 8px;
      }
    }

    .isDebitedText {
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      color: #FFFFFF;
      cursor: pointer;
      padding-top: 4px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: -3px;
    height: 8px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 0 0 8px 8px;
    border: 1px solid #d2d2d7;
    border-top: none;
    border-bottom: none;
  }

  @include for-size(phone-only) {
    i {
      font-size: 16px;
    }
  }
  i {
    display: flex;
  }

  img {
    width: 16px;
    margin-bottom: 1px !important;
  }
}

.below_strip_transparent {
  background: #ffffff;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  padding: 10px;
  min-height: 44px;
  margin-top: -6px;
}


.below_strip_heading {
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-left: 8px;
  display: flex;
  align-items: center;

  [dir='rtl'] & {
    margin-left: 0px;
    margin-right: 8px;
  }

  i {
    margin-left: 9px !important;
    margin-right: 0px !important;

    [dir='rtl'] & {
      margin-left: 0px !important;
      margin-right: 9px !important;
    }
  }
}

.below_strip_heading b {
  font-weight: 700;
}

.below_strip_heading_cancel {
  margin-inline: 8px !important;
}

.auction_type {
  padding: 0px 8px 0 6px;
  height: 24px;
  font-weight: 500;
  line-height: 16px;
  font-size: 12px;
  display: flex;
  align-items: center;

  html[dir='rtl'] & {
  padding: 0px 6px 0 8px;
  }
}
.auction_type_Live {
  padding: 0px 8px 0px 6px;
  html[dir='rtl'] & {
    padding: 0px 6px 0 8px;
    }
}

.statustext{
  display: flex;
  align-items: center;
}
.tag_wrapper {
  display: flex;
  grid-gap: 8px;
  position: absolute;
  top: 14px;
  left: 14px;
  border-radius: 4px;
  overflow: hidden;

  html[dir='rtl'] & {
    left: auto;
    right: 14px;
  }
}

.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  font-weight: 500;
  font-size: 12px;
  text-transform: capitalize;
  background: #6f10a2;
  color: white;
  border-radius: 4px;

  i {
    display: none;
    margin-right: 4px;

    html[dir='rtl'] & {
      margin-right: 0;
      margin-left: 4px;
    }
  }

  &[data-type='upcoming'] {
    background: #FFEECD;
    color: #1D252D;

    .timerOne {
      i {
        display: flex;
        color: #1D252D;
      }
    }
  }

  &[data-type='awaiting-rejected'] {
    background: #ffeecd;
    color: #191919;

    .timerOne {
      i {
        display: flex;
        color: #191919;
      }
    }
  }


  &[data-type='live'] {
    background: #048848;
    color: #fff !important;

    .auction_type {
      display: flex;
      align-items: center;

    }
  }

  &[data-type='past'] {
    background: #485661;

    .auctionOne {
      i {
        display: block;
      }
    }
  }

  &[data-type='cancel'] {
    background: #36434E;

    .cancelOne {
      i {
        display: block;
      }
    }
  }

  &[data-type='sub-auction-closed-sold'] {
    background: #E6F2ED;
    color: #1D252D;

    .auctionOne {
      i {
        display: block;
      }
    }
  }
}

.infathTag {
  padding: 4px 8px;
  height: 24px;
  background: #F0F3FD;
  border: none;
  border-radius: 4px;
  
  .inner{
   font-weight: 500;
   font-size: 12px;
   line-height: 16px;
   text-transform: capitalize;
   color: #2D318E;
  }
}

.price {
  display: flex;
  align-items: baseline;
  justify-content: start;
  grid-gap: 4px;
  color: #510c76;

  &__currency {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $color-gray-light;

    html[dir='rtl'] & {
      font-family: "Noto Kufi Arabic", sans-serif;
    }
  }

  &__value {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: #222222;

    html[dir='rtl'] & {
      font-family: $arabic-font;
    }
  }

  &__auction_time {
    text-align: right;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #222222;
    white-space: nowrap;

    html[dir='rtl'] & {
      font-size: 17px;
      text-align: left;
    }
  }
}

.tooltip {
  position: absolute;
  left: 0;
  bottom: 75px;

  &.rightTp {
    right: 15px;
    left: inherit;
    z-index: 1;
  }

  @include for-size(phone-only) {
    :hover {
      display: none;
    }
  }
}

.cta {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: end;
  justify-self: end;
  grid-gap: 10px;
}

// --------------------------------RTL STYLE -----------------------------------------------------------

html[dir='rtl'] {
  .attribute {
    &__name {
      @media screen and (max-width: 767) {
        display: none;
      }
    }
  }

  .overlayBottom {
    left: 0;
    right: auto;
    justify-content: left;

    &__tooltip {
      top: 50%;
      right: auto;
      left: 70%;

      &::after {
        left: auto;
        transform: translateY(-50%) rotate(180deg);
        right: 99%;
      }
    }
  }
}

.imageLink {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

%btn {
  appearance: none;
  margin: 0;
  padding: 0;
  border: 1px solid;
  outline: none;
  color: inherit;
  display: flex;
  place-items: center;
  place-content: center;
  width: 48px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
}

.btn_blue_o,
.btn_blue {
  @extend %btn;
}

.btn_blue_o {
  border-color: #84b4ff;
  background-color: #ebf2ff;
  color: #1d74ff;
}

.btn_blue {
  border-color: #1d74ff;
  background-color: #1d74ff;
  color: #fff;
}

.font_12px {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #4C4C4C;
}

.color_inherit {
  color: inherit;
}

.cardback {
  background-color: #6f10a2;
  border-radius: 8px;
  box-shadow: 0px 8px 10px rgb(161 168 191 / 25%), 0px 1px 2px rgb(0 0 0 / 10%);
}

.rotateImg {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.price_sqm {
  padding: 20px 16px 0px 16px;
  margin: 0px;
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  color: #191919;
  display: flex;
  margin-bottom: auto;
  width: 100%;

  @include for-size(phone-only) {
    padding: 20px 12px 0px 12px;
  }
}

.IconBoxF {
  position: absolute;
  top: 20px;
  right: 20px;
}

.closed_time {
  font-size: 13px;
  display: block;
  text-align: center;
  color: #737373;
}

.textColorWhite {
  color: white;
}

.closedDate {
  color: #222222;
  font-size: 18px;
  font-weight: 500;
}

.grid_gap_0 {
  grid-gap: 0px !important;
}

.OnlineTag {
  height: 24px;
  padding: 4px 8px;
  border: none;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: #F0EBF9;

  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #1D252D;
    text-transform: capitalize;
  }
}

.Pcount{
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #191919;
  span{
    font-weight: 500;
    font-size: 18px;
  }
}
.pDate{
  font-weight: 400;
font-size: 16px;
line-height: 16px;
color: #191919;
  span{
    color: #737373;
  }
  display: flex;
}
.TooltipWrap{
  position: relative;

}
.titleBox{
  display: inline;
  grid-gap: 4px;
}
.TooltipButton, .TooltipNumber{
  color: #6F10A2
}
.Tooltip_auction{
  position: absolute;
}
.CardAddress {
  display: block;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-top: 2px;
  color: #737373;
  width: 100%;

  @include for-size(phone-only) {
    max-width: 100%;
  }
  .TooltipWrap{
    display: inline-flex;
    justify-content: center;
    z-index: 2;
    align-items: center;
    width: 25px;
    height: 25px;
    margin: 0 5px;
    .TooltipNumber{
      font-size: 14px;
    }
    .TooltipButton{
      font-size: 14px;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #F9F5FE;
      border-radius: 50%;
      z-index: -1;
      width: 25px;
      margin: auto;
      height: 25px;
      
    }
    .Tooltip_auction {
      top: 4px;
      left: 0;
      right: 0;
    }
  }
}
.tooltipStyle{
  margin: -5px 2px;
}

.cardTitleAndLogoContainer {
  width: 100%
}

.brokerfirmLogo {
  float: right;
  max-width: 90px;
  max-height: 48px;
  @include for-size(phone-only) {
    max-width: 78px;
    max-height: 42px;
  }
}
.auctionEllipse{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.auctionTitleSingleLine {    
    -webkit-line-clamp: 1;
}

.blurFooter {
  filter: blur(3px);
}

.EditStrip_Wrapper {
  display: flex;
  padding: $pad8;
  border-radius: $radius0 $radius0 $radius4 $radius4;
  border: 1px solid $Red-200;
  background: $Red-50;
  gap: $gap8;
  margin-top: $mrg0;
  z-index: 1;
  i {
    display: flex;
  }

  .cardBorder {
    border: none;
  }
}

.EditStrip_Text_Wrapper {
  display: flex;
  line-height: $lineH16;
  flex-direction: column;
  gap: $gap4;
}

.EditStrip_heading {
  color: $Cool-Grey-900;
  font-size: $font14;
  font-weight: $font-semi-bold;
  line-height: $lineH20;
}

.EditStrip_below_heading {
  color: $Cool-Grey-500;
  font-size: $font13;
  font-weight: $default-font-weight;
  line-height: $lineH16;
}

.disabledDiv {
  pointer-events: auto; /* Ensure the div itself is clickable */
}

.disabledDiv * {
  pointer-events: none; /* Disable interaction for all child elements */
}

.editCard {
    grid-template-rows: 160px auto 30px;
}

.cardBorder {
  height: 100%;
  border: 1px solid #d2d2d7;

  @include for-size(landscape-phone) {
    height: auto;
  }
}

.cardBtmPad {
  padding-bottom: 16px;
}