@import '../../../styles/globalVariables';
@import '../../../styles/mixins';
.tooltipWrapper {
  background-color: $color-gray;
  color: #fff;
  padding: 4px 12px;
  position: absolute;
  top: 30px;
  height: fit-content;
  max-width: 214px;
  width: max-content;
  // width: 214px;
  text-align: center;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  visibility: hidden;
  justify-content: space-between;
  z-index: 1;
  left: 50%;
  transform: translate(-50%);
  // overflow: visible;
  &::after {
    content: ' ';
    position: absolute;
    top: var(--top, -8px);
    border-top: none;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid $color-gray;
    left: var(--left, 50%);
    transform: var(--transform, translate(-50%));
    right: var(--right, auto);
    display: var(--display, 'inherit');
    
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    html[dir='rtl'] & {
      font-family: $arabic-font;
    }
    text-align: left;
  }
  &.right {
    &::after {
      right: 30px;
      left: 85%;
      transform: translate(-50%) scaleX(-1);
      border-right: 0px solid transparent;
    }
  }
  &.left {
    &::after {
      left: 30px;
      left: 12%;
      transform: none;
      border-right: 0px solid transparent;
    }
  }
}
