@import '../../../styles/globalVariables';

.tooltipContainer{
    background-color: $color-gray;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    right: 16px;
    color: #fff;
    text-align: left;
    padding: 10px 14px;
    border-radius: 4px;
    position: absolute;
    top: 34px;
    left: -30px;
    z-index: 6;
    max-width: 300px;
    width: 300px;
    pointer-events: none;
    html[dir='rtl'] & {
      text-align: right;
    }

    @include for-size(phone-only){
      max-width: 280px;
      width: 280px;
    }
    @media only screen and (max-width: 399.98px) {
      max-width: 280px;
      width: 280px;
    }
  }
  .tooltipArrow{
      position: absolute;
      left: 40px;
      top: -7px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid $color-gray;
  }
  
.tooltipContainer_new{
  background-color: $color-gray;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  text-align: left;
  padding: 4px 8px;
  border-radius: 4px;
  position: absolute;
  top: 34px;
  left: -30px;
  z-index: 6;
  // width: 160px;
  max-width: 250px;
  transform: translate(-50%);
  
  pointer-events: none;
  html[dir='rtl'] & {
    text-align: right;
    max-width: 250px;
    

  }

  @include for-size(phone-only){
    max-width: 250px;
    html[dir='rtl'] & {
      text-align: right;
      max-width: 250px;
    }
  }
  @media only screen and (max-width: 399.98px) {
    max-width: 250px;
    html[dir='rtl'] & {
      max-width: 250px;
    }
  }
}
.tooltipArrow_new{
    position: absolute;
    left: 40px;
    top: -7px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid $color-gray;
    transform: translate(-50%);
}
.tooltipArrow_new1{
    position: absolute;
    left: 40px;
    top: 47px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid $color-gray;
    transform: translate(-50%);
}

.fieldInfo {
  padding-top: 4px;
  margin-left: 8px;
  html[dir='rtl'] & {
    margin-left: 0px;
    margin-right: 9px;
  }
}

.FalInfoWrapper{
  margin: 0px;
  padding: 4px;
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.FalInfo {
  background-color: #191919;
  color: #FFFFFF;
  padding: 8px 12px;
  max-width: 343px;
  text-align: center;
  border-radius: 8px;
  html[dir='rtl'] & {
    text-align: center;
    max-width: 343px;
  }
}

.FalToolTip {
  position: absolute;
    left: 40px;
    top: 74px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid $lightBlack;
}