@import '../../styles/globalVariables';

.editBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 336px;
    padding-top: $pad12;
    @include for-size(tablet-up) {
        max-width: 432px;
        padding: $pad12 $pad16 $pad0 $pad16;
        height: auto;
    }
}

.warning_iconBox {
    display: flex;
    margin-bottom: $mrg16;
    i {
        height: $height48;
    }
}

.Title {
    display: flex;
    align-self: stretch;
    color: $lightBlack;
    justify-content: center;
    font-size: $font18;
    font-weight: $font-semi-bolder;
    line-height: $lineH24;
    text-align: center;
}

.text {
    display: flex;
    color: $Cool-Grey-900;
    text-align: center;
    font-size: $font14;
    font-weight: $default-font-weight;
    line-height: $lineH20; 
    @include for-size(tablet-up) {
        margin-top: $mrg24;
    }
}

.subText {
    display: flex;
    color: $Cool-Grey-900;
    text-align: center;
    font-size: $font14;
    font-weight: $default-font-weight;
    line-height: $lineH20; 
    margin-top: $mrg8;
}

.info_iconBox {
    display: flex;
    padding: $pad8;
    align-items: center;
    gap: $gap8;
    border-radius: $radius4;
    background: #F0F3FD;
    margin-top: $mrg24;
}

.info_strip {
    display: flex;
    color: $Cool-Grey-900;
    font-size: $font14;
    font-weight: $default-font-weight;
    line-height: $lineH20; 
}

.btnCustom {
    width: $per100;
    min-height: $height44;
    padding: $pad0 $pad12;
    border-radius: $radius4;
    background: $violet;
    border: none;
    color: $primary-bg;
    font-size: $font16;
    font-weight: $font-semi-bold;
    line-height: $lineH20; 
    margin-top: $mrg16;
    cursor: pointer;
}

.editMode {
    margin-top: $mrg32;
}

.TitleMargin {
    margin-bottom: $mrg24;
    @include for-size(tablet-up) {
        margin-bottom: $mrg0;
    }
}