@import '../../../styles/globalVariables';

.transactionTop {
  display: flex;
  align-items: center;
  gap: 8px;
}

.transactionContainer {
  min-width: 416px;
  margin-top: 24px;
  @include for-size(phone-only) {
    width: 100%;
    min-width: 100%;
    margin-top: 0px;
  }
}

.statusIcon {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  i {
    font-size: 14px;
    display: flex;
  }
}

.withdraw_initiated {
  background: #FFF0D9;

  i {
    color: #DFA138;
  }

}

.on_hold {
  background: #FCE6E6;

  i {
    color: #EE5441;
  }
}

.credited {
  background: #E4FCF3;

  i {
    color: #10A26F;
  }
}

.withdraw_successful {
  background: #FFF0D9;

  i {
    color: #DFA138;
  }
}

.withdraw_failed {
  background: #FCE6E6;

  i {
    color: #EE5441;
  }

}

.captured {
  background: #FFF0D9;
  i {
    color: #F39200;
    font-size: 20px;
  }
}

.refunded {
  background: #DCF4FF;

  i {
    color: #29B9FB;
  }
}

.transactionAmount {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #222222;

  & .transactionCurrency {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #737373;
  }
}

.transactionStatus {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #737373;

  & .wonAuction {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #191919;
    margin-left: 4px;
    [dir='rtl'] & {
      margin-right: 4px;
      margin-left: 0;
    }
  }
}

.transactionHistory {
  margin-top: 24px;
  @include for-size(phone-only) {
    margin-top: 28px;
  }

  & .title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #191919;
  }

  & .historyCard {
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px dashed #D2D2D7;
    margin-top: 8px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    .auctionLiist{
      border-bottom: 1px dashed #D2D2D7;
      padding-bottom: 11px;
      margin-bottom: 11px;
      display: block;
      &:last-child{
        border: 0;
        margin-bottom:0;
        padding-bottom: 0;
      }
    }

    & .topHistoryCard {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .textFlow{
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #737373;
      display: block;
      margin-top: 4px;

    }

    .auctionId {
      color: #6F10A2;
    }
  }
}

.transactionFooter {
  display: flex;
  align-items: baseline;
  grid-gap: 4px;
  & .transactionFooterText {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4C4C4C;
  }

  & .transactionCall {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6F10A2;
  }
}

.DownloadInvo{
  color: #6F10A2;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  grid-gap: 4px;
  cursor: pointer;
}

.PriceBarList{
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #222222;
    .sarTo{
      color:#717171;
      font-weight: 400;
      font-size: 10px;
      line-height: 10px;
    }
}
.wonAuction{
  .wTitle{
    font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #222222;
  }
  .wTitles{
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #737373;
  }
}

.ReasonBox{
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #737373;
  display: grid;
  grid-template-columns: 43px 1fr;
  word-break: break-all;
  padding-top: 4px;
  span{
    font-weight: 500;
    color: #191919;
  }
}
