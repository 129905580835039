@import '../../../../styles/globalVariables';
@import '../../../../styles/mixins';
.topContainer{
  margin-top: 62px !important; 
}
.footer_wrapper {
  display: flex;
  margin-top: 32px;
  &.aCardF{
    justify-content: space-between;
  }
  @include for-size(phone-only) {
    margin-top: 24px;
  }
}
.footer {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  padding-top: 2px;
  @media screen and (min-width: 480px) and (max-width: 960) {
    padding-bottom: 0;
  }
}
.w_50 {
  // width: 50%;
}
.text_end {
  text-align: end;
}
