
@import '../../../styles/globalVariables';

.liveTagWrapper{
    position: relative;
    margin-right: 20px;
    html[dir='rtl'] &{
        margin-right:0px;  
        margin-left: 16px;
    }
    .liveTag {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;
        background-color: rgb(255 255 255 / 90%);
        text-align: center;
        line-height: 100px;
        border-radius: 100%;
        font-size: 1.3rem;
        left: 8px;
        html[dir='rtl'] &{
        left: auto;
        right: -2px;
        }    
    }
    
    .liveTag::after, .liveTag::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 8px;
        height: 8px;
        border: 1px solid #fff;
        border-radius: 50%;
        z-index: -1;
        animation: live 1.2s ease-in-out infinite;
    }
}



@keyframes live {
    0% {
        transform: scale(1, 1);
        opacity: 1;
    }

    100% {
        transform: scale(2.2, 2.2);
        opacity: 0;
    }
}